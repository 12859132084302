
import { KeepAlive } from '@/utils/decorators'
import { Vue, Component } from 'vue-property-decorator'
import Dialog from '@/components/assetScheduling/Dialog.vue'
import { ElForm } from 'element-ui/types/form'
// import { twoDecimal } from '@/utils/validate'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'Disposal',
  components: {
    AssetsDialog: Dialog,
    UploadFile: UploadFile
  }
})
@KeepAlive
export default class BraceletList extends Vue {
  dialogVisible = false

  formData = {
    remarks: '', // 备注
    projectId: '', // 所属项目
    orderDate: '', // 工单日期
    disposalType: '', // 资产清单
    totalDisposalAmount: '', // 处置总金额
    disposalCosts: '', // 处置费用
    disposalReason: '', // 处置原因
    assetList: [], // 资产清单
    fileList: [],
    disposeId: ''
  }

  private validtor99999 = (rule: object, value: string, callback: Function) => {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
    if ((value && !reg.test(value)) || Number(value) > 99999.99) {
      return callback(new Error('请输入0到99999.99的数'))
    } else {
      return callback()
    }
  }

  rules = {
    projectId: [{ required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }],
    disposalType: [{ required: true, message: '请选择处置类型', trigger: ['blur', 'change'] }],
    orderDate: [{ required: true, message: '请选择处置时间', trigger: ['blur', 'change'] }],
    disposalCosts: [{ validator: this.validtor99999, trigger: ['blur', 'change'] }],
    fileList: [{ required: true, message: '请上传附件', trigger: ['blur', 'change'] }],
    disposeId: [{ required: true, message: '请选择处置审核人', trigger: ['blur', 'change'] }],
    assetPrice: [
      { required: true, message: '请输入处置金额', trigger: ['blur', 'change'] },
      { validator: this.validtor99999, trigger: ['blur', 'change'] }]
  }

  pickerOptions = {
    disabledDate: (time: Date) => {
      // 大于当前日期禁止选择
      return time.getTime() > Date.now()
    }
  }

  private disposeCustomList = []

  get projectList () {
    return this.$store.state.projectList
  }

  get userInfo () {
    return JSON.parse(sessionStorage.getItem('userInfo') || '{}')
  }

  get totalPrice () {
    return this.formData.assetList.reduce((prev, next: any) => {
      return Number((prev + +next.assetPrice).toFixed(2))
    }, 0)
  }

  get assetCode () {
    return this.$route.params.assetCode as string
  }

  created () {
    // do something
    this.formData.projectId = this.$route.params.projectId as string
    this.assetCode && this.getOnlyTable()
    this.formData.projectId && this.getDisposeCustomList()
  }

  // 表头颜色样式
  rowClass ({ row, column, rowIndex, columnIndex }: any) {
    const index = [8]
    if (rowIndex === 0 && (index.includes(columnIndex))) {
      return 'color:red'
    }
  }

  choosedAssets (data: any) {
    const map = new Map()
    const tableData = this.formData.assetList.concat(data)
    this.formData.assetList = tableData.filter((item: any) => !map.has(item.assetId) && map.set(item.assetId, 1))
  }

  projectChange () {
    this.formData.assetList = []
    this.getDisposeCustomList()
    this.formData.disposeId = ''
  }

  delAsset (index: number) {
    this.formData.assetList.splice(index, 1)
  }

  // 获取从资产清单页面跳转过来的本条资产清单
  getOnlyTable () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetByPage, {
      assetCode: this.assetCode
    }).then(res => {
      this.formData.assetList = res.list || []
    })
  }

  // 获取处置人信息
  getDisposeCustomList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTransferListByProjectId, {
      projectId: this.formData.projectId,
      nodeCode: '3'
    }).then((res) => {
      this.disposeCustomList = res || []
    })
  }

  addAssets () {
    if (!this.formData.projectId) {
      this.$message({ type: 'warning', message: '请先选择所属项目' })
      return
    }
    this.dialogVisible = true
    ;(this.$refs.dialog as any).toggleSelection(this.formData.assetList)
  }

  onSuccess (data: any) {
    (this.formData.fileList as any).push(data)
  }

  onRemove (key: any) {
    const index = this.formData.fileList.findIndex((item: any) => item.fileUrl === key)
    this.formData.fileList.splice(index, 1)
  }

  submit () {
    (this.$refs.form as ElForm).validate().then(() => {
      if (!this.formData.assetList.length) {
        this.$message({ type: 'warning', message: '请添加处置资产' })
        return
      }
      ;(this.$refs.table as ElForm).validate().then(() => {
        this.formData.assetList = this.formData.assetList.map((item: any) => {
          return {
            assetId: item.assetId,
            assetPrice: item.assetPrice
          }
        }) as any
        this.formData.totalDisposalAmount = this.totalPrice as any
        this.$axios.post(this.$apis.assetScheduling.insertAssetHandle, this.formData).then(() => {
          this.$router.back()
        })
      })
    })
  }
}
