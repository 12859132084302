
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AssetList, TypesTree } from '@/types/assetScheduling'
@Component({
  name: 'Dialog'
})
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '仅处于空闲状态的资产才可领用，如果领用资产非空闲，请联系保管员进行资产更新' }) private tips!: string
  @Prop({ default: '' }) private projectId!: string
  @Prop({ default: '1' }) private assetState!: string // 空闲可领用
  @Prop({ default: false }) private isShowPerson!: boolean // 是否展示保管人
  @Prop({ default: false }) private isProject!: boolean // 是否有项目权限
  private info= {
    assetCodeName: '',
    assetType: '',
    projectId: '',
    assetState: ''
  }

  private tableData: AssetList[] = []
  private list: TypesTree[] = []
  private page = 1
  private size = 10
  private total = 0
  private props = {
    children: 'childNode',
    label: 'assetTypeName',
    value: 'assetType'
  }

  private choosedAssets: AssetList[] = []

  @Watch('projectId', { immediate: true })
  getProjectId (val: string) {
    if (val) {
      this.info.projectId = val
    }
  }

  @Watch('showDialog', { immediate: true })
  getShowDialog (val: string) {
    if (val) {
      this.getData()
    }
  }

  @Watch('assetState', { immediate: true })
  getAssetState (val: string) {
    this.info.assetState = val
    this.getData()
  }

  created () {
    this.getList()
  }

  onSearch () {
    this.page = 1
    this.getData()
    this.toggleSelection([])
  }

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList, {
      level: 2
    }).then(res => {
      this.list = res || []
    })
  }

  getData () {
    const params = this.isProject ? { isProject: '1' } : {}
    this.$axios.get(this.$apis.assetScheduling.selectAssetByPage, {
      ...this.info,
      page: this.page,
      size: this.size,
      ...params
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    })
  }

  handleSelectionChange (val: any) {
    this.choosedAssets = val
  }

  // 改变选中状态
  toggleSelection (rows: AssetList[]) {
    this.$nextTick(() => {
      if (rows.length > 0) {
        (this.$refs.table as any).clearSelection()
        rows.forEach(row => {
          (this.$refs.table as any).toggleRowSelection(this.tableData.find(item => {
            return item.assetCode === row.assetCode
          }), true)
        })
      } else {
        (this.$refs.table as any).clearSelection()
      }
    })
  }

  // 跨页多选唯一标识
  getOnlyNo (row: AssetList) {
    return row.assetCode + row.assetName
  }

  onSubmit () {
    this.closeDialog()
    this.$emit('success', this.choosedAssets)
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
  }
}
